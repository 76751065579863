import React from "react";
import { Link } from "react-router-dom";

export default function Second() {
  return (
    <section
      className="section bg-center bg-cover effect-section"
      style={{ backgroundImage: "url(static/img/computerdesk.jpg)" }}
    >
      <div className="mask theme-bg opacity-8"></div>
      <div className="container">
        <div className="row justify-content-center p-50px-t">
          <div className="col-lg-8 text-center">
            <h2 className="white-color h1 m-20px-b">Blast Off Tutoring</h2>
            <ol className="breadcrumb white justify-content-center">
              <li>
                <Link to="/">Home</Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}
