import React from "react";
import * as emailjs from "emailjs-com";
class ContactSm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "gmail",
        "contact_template",
        ".mailform",
        "user_O5zGFaaUZDdUqeKCF5TtL"
      )
      .then()
      .catch();
    this.setState({
      name: "",
      email: "",
      message: "",
    });
  };

  render() {
    return (
      <section id="contact" className="section gray-bg">
        <div className="container">
          <div className="row md-m-25px-b m-45px-b justify-content-center text-center">
            <div className="col-lg-8">
              <h3 className="h1 m-20px-b p-20px-b theme-after after-50px">
                Let's start working together
              </h3>
              <p className="m-0px font-2">
                Send us a message. We will respond promptly with useful
                information about what we can offer you and how we could help!
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-7 m-15px-tb">
              <div className="white-bg box-shadow p-30px">
                <div className="p-20px-b">
                  <h5 className="m-0px">Contact Us</h5>
                </div>
                <form
                  className="rd-mailform mailform"
                  onSubmit={this.handleSubmit.bind(this)}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-control-label">Name</label>
                        <input
                          id="contact-name"
                          type="text"
                          name="name"
                          placeholder="John Doe"
                          data-constraints="@Required"
                          className="form-control"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.name}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Email address
                        </label>
                        <input
                          id="contact-email"
                          type="email"
                          name="email"
                          placeholder="name@example.com"
                          data-constraints="@Required"
                          className="form-control"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-control-label">Comment</label>
                        <textarea
                          className="form-control"
                          id="contact-message"
                          name="message"
                          rows="3"
                          placeholder="Hi there, I would like to ..."
                          data-constraints="@Required"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.message}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 p-10px-t">
                      <button
                        className="m-btn m-btn-theme m-btn-radius"
                        type="submit"
                        name="send"
                        id="submit"
                      >
                        Send Message
                      </button>
                      <div className="snackbars" id="form-output-global"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3 m-15px-tb">
              <div className="border-all-10 border-color-white p-40px-tb p-20px-lr theme-bg box-shadow h-100">
                <h5 className="font-1 white-color m-10px-b">
                  Our Address Info
                </h5>
                <p className="white-color-light m-30px-b">
                  Plainview, NY 11803
                </p>
                <h5 className="font-1 white-color m-10px-b">
                  Our Contact Info
                </h5>
                <p className="m-5px-b">
                  <a
                    className="white-color-light"
                    href="mailto:support@domain.com"
                  >
                    info@blueavalanche.com
                  </a>
                </p>
                <p className="m-0px">
                  <a className="white-color-light" href="tel:917-737-2695">
                    (917) 737-2695
                  </a>
                </p>

                <h5 className="font-1 white-color m-10px-b m-30px-t">
                  Follow Us
                </h5>
                <div className="social-icon si-30 white radius nav">
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSm;
