import React from "react";
import HomeBanner from "./HomeBanner";
import WhyChooseUs from "./WhyChooseUs";
import AuxSect1 from "./AuxSect1";
import Testimonials from "./Testimonials";
import Pricing from "./Pricing";
import Contact from "./ContactSm";
import VideoSect from "./VideoSect";
import SmHero from "./SmHero";

function Home() {
  return (
    <div>
      <HomeBanner />
      <WhyChooseUs />
      <AuxSect1 />
      <VideoSect />
      <Pricing />
      <SmHero />
      <Contact />
    </div>
  );
}

export default Home;
