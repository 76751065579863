import React from "react";
import { Link } from "react-router-dom";
function Pricing() {
  return (
    <div>
      <section id="price" className="section gray-bg">
        <div className="container">
          <div className="row md-m-25px-b m-45px-b justify-content-center text-center">
            <div className="col-lg-8">
              <h3 className="h1 m-20px-b p-20px-b theme-after after-50px">
                Pricing Plans
              </h3>
              <p className="m-0px font-2">
                The plans listed are just estimates of the final cost of your
                site. We will provide you with a detailed invoice catered
                specifically to your needs as we move further along in the
                development process.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-3 m-15px-tb">
              <div className="price-table">
                <div className="pt-head">
                  <h5>
                    <span>$</span>199
                  </h5>
                </div>
                <div className="pt-body">
                  <h6>Starter Package</h6>
                  <ul className="list-type-02">
                    <li>
                      <i className="fas fa-check"></i> Single Page Static Site
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Two Stages Of Review
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Mobile Friendly Design
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Navigation Bar
                    </li>
                    <li>
                      <i className="fas fa-check"></i> One Month Of Maintenance
                    </li>
                  </ul>
                </div>
                <div className="pt-footer">
                  <Link
                    className="m-btn m-btn-theme m-btn-radius"
                    to="/getaquote"
                  >
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 m-15px-tb">
              <div className="price-table">
                <div className="pt-head">
                  <h5>
                    <span>$</span>399
                  </h5>
                </div>
                <div className="pt-body">
                  <h6>Basic Package</h6>
                  <ul className="list-type-02">
                    <li>
                      <i className="fas fa-check"></i> Three Page Static Site
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Three Stages of Review
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Mobile Friendly Design
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Image Gallery
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Two Months Of Maintenance
                    </li>
                  </ul>
                </div>
                <div className="pt-footer">
                  <Link
                    className="m-btn m-btn-theme m-btn-radius"
                    to="/getaquote"
                  >
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 m-15px-tb">
              <div className="price-table active">
                <div className="pt-head">
                  <label className="msg">Popular Choice</label>
                  <h5>
                    <span>$</span>649
                  </h5>
                </div>
                <div className="pt-body">
                  <h6>Pro Package</h6>
                  <ul className="list-type-02">
                    <li>
                      <i className="fas fa-check"></i> Five Page Dynamic Site
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Comprehensive Review
                      Process
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Admin Panel
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Mobile First Design
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Three Months Of
                      Maintenance
                    </li>
                  </ul>
                </div>
                <div className="pt-footer">
                  <Link
                    className="m-btn m-btn-theme2nd m-btn-radius"
                    to="/getaquote"
                  >
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 m-15px-tb">
              <div className="price-table">
                <div className="pt-head">
                  <h5>
                    <span>$</span>1499
                  </h5>
                </div>
                <div className="pt-body">
                  <h6>Enterprise Package</h6>
                  <ul className="list-type-02">
                    <li>
                      <i className="fas fa-check"></i> 5+ Page Dynamic Site
                    </li>
                    <li>
                      <i className="fas fa-check"></i> eCommerce Store
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Admin Panel and Account
                      Interface
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Live Ordering
                    </li>
                    <li>
                      <i className="fas fa-check"></i> And Much More...
                    </li>
                  </ul>
                </div>
                <div className="pt-footer">
                  <Link
                    className="m-btn m-btn-theme m-btn-radius"
                    to="/getaquote"
                  >
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
