import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12 m-15px-tb mr-auto">
              <div className="m-20px-b">
                <img
                  src="static/img/blueAvalancheLight-01.svg"
                  title=""
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 m-15px-tb">
              <h6 className="white-color">Useful</h6>
              <ul className="list-unstyled links-white footer-link-1">
                <li>
                  <a
                    href="https://theblog.adobe.com/a-comprehensive-guide-to-web-design/"
                    target="_blank"
                  >
                    Web Design
                  </a>
                </li>
                <li>
                  <a
                    href="https://moz.com/beginners-guide-to-seo"
                    target="_blank"
                  >
                    Development
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.quicksprout.com/the-beginners-guide-to-online-marketing/"
                    target="_blank"
                  >
                    Online Marketing
                  </a>
                </li>
                <li>
                  <a
                    href="https://moz.com/beginners-guide-to-seo"
                    target="_blank"
                  >
                    SEO Marketing
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 m-15px-tb">
              <h6 className="white-color">About Us</h6>
              <ul className="list-unstyled links-white footer-link-1">
                <li>
                  <a href="#">Support Center</a>
                </li>
                <li>
                  <Link to="#about">Customer Support</Link>
                </li>
                <li>
                  <Link to="/home#about">About Us</Link>
                </li>
                <li>
                  <a href="#">Copyright</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 m-15px-tb">
              <h6 className="white-color">Information</h6>
              <address>
                <p className="white-color-light m-5px-b">
                  Located in Plainview, NY
                  <br />
                  11803
                </p>
                <p className="m-5px-b">
                  <a
                    className="theme2nd-color border-bottom-1 border-color-theme2nd"
                    href="mailto:info@blueavalanche.com"
                  >
                    info@blueavalanche.com
                  </a>
                </p>
                <p className="m-5px-b">
                  <a
                    className="theme2nd-color border-bottom-1 border-color-theme2nd"
                    href="tel:917-737-2695"
                  >
                    (917) 737-2695
                  </a>
                </p>
              </address>
              <div className="social-icon si-30 theme2nd nav">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom footer-border-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <ul className="nav justify-content-center justify-content-md-start m-5px-tb links-white font-small footer-link-1">
                <li>
                  <a href="#">Privace &amp; Policy</a>
                </li>
                <li>
                  <Link to="/getaquote">Get a Quote</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <p className="m-0px font-small white-color-light">
                © 2020 copyright all rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
