import React from "react";

export default function VideoSect() {
  return (
    <section className="section">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5 m-15px-tb">
            <h2 className="h1 m-25px-b">Find Out If You Need a Website</h2>
            <p className="m-5px-b">
              This video will show you some of the values that an online web
              presence brings. Take a look to see what you could be missing out
              on.
            </p>
            <div className="btn-bar p-15px-t">
              <a
                className="m-btn m-btn-radius m-btn-theme"
                href="https://www.forbes.com/sites/nicoleleinbachreyhle/2014/09/29/websites-for-small-businesses/#1285cbbc2026"
                target="_blank"
              >
                More About
              </a>
            </div>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="video-box">
              <img
                className="box-shadow border-radius-5"
                src="static/img/needasite.jpg"
                title=""
                alt="test"
              />
              <a
                className="video-btn white popup-youtube p-center"
                href="https://www.youtube.com/watch?v=ZI1QNsldTZw"
              >
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
