import React from "react";

function WhyChooseUs() {
  return (
    <div>
      <section id="about" className="section gray-bg">
        <div className="container">
          <div className="row md-m-25px-b m-45px-b justify-content-center text-center">
            <div className="col-lg-8">
              <h3 className="h1 m-20px-b p-20px-b theme-after after-50px">
                Why Choose Us
              </h3>
              <p className="m-0px font-2">
                Blue Avalanche develops custom websites to suit the specific
                needs of our clients. Elevate your business with a refreshing
                online presence and wow your customers.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 m-15px-tb">
              <div className="p-50px-tb p-35px-lr box-shadow-hover hover-top hover-rotate white-bg text-center border-radius-5">
                <div className="ef-1 icon-80 theme-bg border-radius-50 theme2nd-color d-inline-block m-20px-b hr-rotate-after">
                  <i className="icon-desktop white-color"></i>
                </div>
                <h5 className="h6 m-10px-b">Getting Online</h5>
                <p className="m-0px">
                  A striking web presence can do wonders for all types of
                  businesses. Don't miss out on customers because of an old,
                  clunky website.
                </p>
              </div>
            </div>
            <div className="col-md-4 m-15px-tb">
              <div className="p-50px-tb p-35px-lr box-shadow-hover hover-top hover-rotate theme-bg text-center border-radius-5">
                <div className="ef-1 icon-80 white-bg border-radius-50 theme2nd-color d-inline-block m-20px-b hr-rotate-after">
                  <i className="theme-color icon-tools"></i>
                </div>
                <h5 className="white-color h6 m-10px-b">
                  Collaborative Design
                </h5>
                <p className="m-0px white-color-light">
                  We will work hand-in-hand with you to create the perfect
                  representation of your business and help convey your message
                  to your customers.
                </p>
              </div>
            </div>
            <div className="col-md-4 m-15px-tb">
              <div className="p-50px-tb p-35px-lr box-shadow-hover hover-top hover-rotate white-bg text-center border-radius-5">
                <div className="ef-1 icon-80 theme-bg border-radius-50 theme2nd-color d-inline-block m-20px-b hr-rotate-after">
                  <i className="white-color icon-mobile"></i>
                </div>
                <h5 className="h6 m-10px-b">Responsiveness</h5>
                <p className="m-0px">
                  Our websites are compatible with all devices that browse the
                  internet to make sure that your customers are seeing you in
                  the best light.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhyChooseUs;
