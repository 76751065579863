import React, { Component } from "react";
import TitleBlock from "./TitleBlock";
import * as emailjs from "emailjs-com";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      companyname: "",
      email: "",
      plan: "",
      message: "",
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSelectChange(event) {
    this.setState({ plan: event.target.value });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    emailjs
      .sendForm(
        "gmail",
        "quote_template",
        ".mailform",
        "user_O5zGFaaUZDdUqeKCF5TtL"
      )
      .then()
      .catch();
    this.setState({
      name: "",
      email: "",
      companyname: "",
      plan: "",
      message: "",
    });
  };

  render() {
    return (
      <main>
        <TitleBlock />
        <section className="section gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 m-15px-tb">
                <div className="white-bg box-shadow p-30px">
                  <div className="p-20px-b">
                    <h5 className="m-0px">Get a Quote</h5>
                  </div>
                  <form
                    className="rd-mailform mailform"
                    onSubmit={this.handleSubmit.bind(this)}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-control-label">Name</label>
                          <input
                            id="contact-name"
                            type="text"
                            name="name"
                            placeholder="John Doe"
                            data-constraints="@Required"
                            className="form-control"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.name}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-control-label">
                            Company name (optional)
                          </label>
                          <input
                            id="company-name"
                            type="text"
                            name="companyname"
                            placeholder="Company Name"
                            className="form-control"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.companyname}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-control-label">
                            Email address
                          </label>
                          <input
                            id="contact-email"
                            type="email"
                            name="email"
                            placeholder="name@example.com"
                            data-constraints="@Required"
                            className="form-control"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.email}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlSelect1">
                            Plan
                          </label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            name="plan"
                            onChange={this.handleSelectChange.bind(this)}
                            defaultValue={this.state.plan}
                          >
                            <option disabled value="">
                              Choose...
                            </option>
                            <option value="Starter Plan">Starter Plan</option>
                            <option value="Basic Plan">Basic Plan</option>
                            <option value="Pro Plan">Pro Plan</option>
                            <option value="Enterprise Plan">
                              Enterprise Plan
                            </option>
                            <option value="Unsure">Not Sure Yet</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-control-label">Comment</label>
                          <textarea
                            className="form-control"
                            id="contact-message"
                            name="message"
                            rows="3"
                            placeholder="Hi there, I am looking to ..."
                            onChange={this.handleChange.bind(this)}
                            value={this.state.message}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 p-10px-t">
                        <button
                          className="m-btn m-btn-theme m-btn-radius w-100"
                          type="submit"
                          name="send"
                        >
                          Get a Quote
                        </button>
                        <div
                          className="snackbars"
                          id="form-output-global"
                        ></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4 m-15px-tb">
                <div className="border-all-10 border-color-white p-40px-tb p-20px-lr theme-bg box-shadow h-100">
                  <h5 className="font-1 white-color m-10px-b">
                    Our Address Info
                  </h5>
                  <p className="white-color-light m-30px-b">
                    Plainview, NY 11803
                  </p>
                  <h5 className="font-1 white-color m-10px-b">
                    Our Contact Info
                  </h5>
                  <p className="m-5px-b">
                    <a
                      className="white-color-light"
                      href="mailto:support@domain.com"
                    >
                      info@blueavalanche.com
                    </a>
                  </p>
                  <p className="m-0px">
                    <a className="white-color-light" href="tel:917-737-2695">
                      (917) 737-2695
                    </a>
                  </p>

                  <h5 className="font-1 white-color m-10px-b m-30px-t">
                    Follow Us
                  </h5>
                  <div className="social-icon si-30 white radius nav">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 m-30px-t">
                <div className="p-15px white-bg box-shadow">
                  <div className="embed-responsive embed-responsive-21by9">
                    <iframe
                      className="embed-responsive-item"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3151.840107317064!2d144.955925!3d-37.817214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1520156366883"
                      allowFullScreen=""
                    ></iframe>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Contact;
