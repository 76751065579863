import React from "react";

function AuxSect1() {
  return (
    <div>
      <section className="section theme-bg" id="features">
        <div className="container">
          <div className="row md-m-25px-b m-45px-b justify-content-center text-center">
            <div className="col-lg-8">
              <h3 className="h1 white-color m-20px-b p-20px-b white-after after-50px">
                You deserve the all time best
              </h3>
              <p className="m-0px font-2 white-color-light">
                At Blue Avalanche we are dedicated to providing our clients with
                the best service in the industry. We provide a wide range of
                services to accomplish your company's needs.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 m-15px-tb">
              <div className="media p-40px-tb p-20px-lr box-shadow hover-top white-bg border-radius-5">
                <div className="icon-80 gray-bg dots-icon border-radius-50 theme-color d-inline-block m-15px-b">
                  <i className="icon-desktop"></i>
                  <span className="dots">
                    <i className="dot dot1"></i>
                    <i className="dot dot2"></i>
                    <i className="dot dot3"></i>
                  </span>
                </div>
                <div className="media-body p-20px-l">
                  <h6>Web Development</h6>
                  <p className="m-0px">
                    We build fully functional websites to suit your specific
                    needs and boost business. Our sites are stunning and sure to
                    impress.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-15px-tb">
              <div className="media p-40px-tb p-20px-lr box-shadow hover-top white-bg border-radius-5">
                <div className="icon-80 gray-bg dots-icon border-radius-50 theme-color d-inline-block m-15px-b">
                  <i className="icon-tools"></i>
                  <span className="dots">
                    <i className="dot dot1"></i>
                    <i className="dot dot2"></i>
                    <i className="dot dot3"></i>
                  </span>
                </div>
                <div className="media-body p-20px-l">
                  <h6>Logo & Identity</h6>
                  <p className="m-0px">
                    If you are looking to re-vamp your brand we will help pick a
                    new color scheme, logo, and develop your brand identity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AuxSect1;
