import React from "react";
import { Link } from "react-router-dom";

export default function SmHero() {
  return (
    <section
      className="p-60px-tb parallax"
      style={{ backgroundImage: "url(static/img/computerlapcroped.jpg)" }}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6 col-lg-8 text-center text-md-left m-15px-tb">
            <h2 className="white-color m-0px">
              Ready to get started? Take your business to the next level!
            </h2>
          </div>
          <div className="col-md-6 col-lg-4 text-center text-md-right m-15px-tb">
            <Link className="m-btn m-btn-radius m-btn-t-white" to="/getaquote">
              Get a free Estimate
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
