import React, { Component } from "react";
import Second from "./Second.js";
import * as emailjs from "emailjs-com";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      companyname: "",
      email: "",
      plan: "",
      message: "",
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSelectChange(event) {
    this.setState({ plan: event.target.value });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    emailjs
      .sendForm(
        "gmail",
        "quote_template",
        ".mailform",
        "user_O5zGFaaUZDdUqeKCF5TtL"
      )
      .then()
      .catch();
    this.setState({
      name: "",
      email: "",
      companyname: "",
      plan: "",
      message: "",
    });
  };

  render() {
    return (
      <main>
        <Second />
        <section className="section gray-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 m-15px-tb">
                <div className="white-bg box-shadow p-30px">
                  <div className="p-20px-b">
                    <h5 className="m-0px">it's time</h5>
                  </div>
                  
                  <a href="http://zacmey7.dreamhosters.com/">
<button className="m-btn m-btn-theme m-btn-radius w-100">Here you go</button>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 m-15px-tb">
                <div className="border-all-10 border-color-white p-40px-tb p-20px-lr theme-bg box-shadow h-100">
                  <h5 className="font-1 white-color m-10px-b">
                    Our Address Info
                  </h5>
                  <p className="white-color-light m-30px-b">
                    Plainview, NY 11803
                  </p>
                  <h5 className="font-1 white-color m-10px-b">
                    Our Contact Info
                  </h5>
                  <p className="m-5px-b">
                    <a
                      className="white-color-light"
                      href="mailto:support@domain.com"
                    >
                      info@blueavalanche.com
                    </a>
                  </p>
                  <p className="m-0px">
                    <a className="white-color-light" href="tel:917-737-2695">
                      (917) 737-2695
                    </a>
                  </p>

                  <h5 className="font-1 white-color m-10px-b m-30px-t">
                    Follow Us
                  </h5>
                  <div className="social-icon si-30 white radius nav">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 m-30px-t">
                <div className="p-15px white-bg box-shadow">
                  <div className="embed-responsive embed-responsive-21by9">
                    <iframe
                      className="embed-responsive-item"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3151.840107317064!2d144.955925!3d-37.817214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1520156366883"
                      allowFullScreen=""
                    ></iframe>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Contact;
