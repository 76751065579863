import React, { Component } from "react";
import { Link } from "react-router-dom";
import particlesJS from "particles.js";

export class HomeBanner extends Component {
  render() {
    return (
      <div>
        <section id="home" className="effect-section gray-bg">
          <div className="effect theme-bg effect-skew"></div>
          <div className="particles-box" id="particles-js"></div>

          <div className="container">
            <div className="row full-screen align-items-center p-100px-tb justify-content-between">
              <div className="col-lg-5 lg-m-40px-t md-m-10px-t">
                <h1 className="white-color display-4 m-15px-b">
                  Take your web presence to the next level
                </h1>
                <p className="font-2 white-color-light m-6px">
                  We provide the finest web consulting services to elevate your
                  business above the competition.
                </p>
                <form className="d-flex flex-row m-5px-b p-1 white-bg input-group">
                  <input
                    type="email"
                    className="form-control border-radius-0 border-0"
                    placeholder="you@yoursite.com"
                  />
                  <Link to="/getaquote">
                    <button
                      className="m-btn m-btn-theme2nd flex-shrink-0"
                      type="submit"
                    >
                      Get Started
                    </button>
                  </Link>
                </form>
              </div>
              <div className="col-lg-6 lg-m-30px-t">
                <img
                  className="max-width-auto"
                  src="static/img/bulb.svg"
                  title=""
                  alt=""
                  width="900px"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomeBanner;
