import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <header className="header-nav header-white">
      <div className="fixed-header-bar">
        <div className="container container-large">
          <div className="navbar navbar-default navbar-expand-lg main-navbar">
            <div className="navbar-brand">
              <a href="index.html" title="Avalanche" className="logo">
                <img
                  src="./static/img/blueAvalancheLight-01.svg"
                  className="light-logo"
                  alt="Avalanche"
                  title=""
                />
                <img
                  src="./static/img/blueAvalanche-01.svg"
                  className="dark-logo"
                  alt="Avalanche"
                  title=""
                />
              </a>
            </div>
            <div
              className="navbar-collapse justify-content-end collapse"
              id="navbar-collapse-toggle"
            >
              <ul className="nav navbar-nav m-auto">
                <li>
                  <Link className="nav-link" to="/#home">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/#about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/#features">
                    Features
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/#price">
                    Price
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/#contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="extra-menu d-flex align-items-center">
              <div className="d-none d-md-block h-btn m-35px-l">
                <Link
                  className="m-btn m-btn-theme2nd m-btn-radius"
                  to="/getaquote"
                >
                  Get a Quote
                </Link>
              </div>
              <button
                type="button"
                className="navbar-toggler collapsed"
                data-toggle="collapse"
                data-target="#navbar-collapse-toggle"
                aria-expanded="false"
              >
                <span className="icon-bar"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
