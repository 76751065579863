import React, { Component } from "react";

export class Preloader extends Component {
  componentDidMount() {
    document.getElementById("loading").style.display = "none";
  }
  render() {
    return (
      <div>
        <div id="loading">
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </div>
      </div>
    );
  }
}

export default Preloader;
