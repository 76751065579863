import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Preloader from "./components/layouts/Preloader";
import Navbar from "./components/layouts/Navbar";
import Home from "./components/pages/home/Home";
import Site from "./components/pages/contact/Site";
import Contact from "./components/pages/contact/Contact";
import Footer from "./components/layouts/Footer";
import ScrollToTop from "./ScrollToTop";
import ReactGa from "react-ga";

export class App extends Component {
  componentDidMount() {
    ReactGa.initialize("UA-172641939-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div
        data-spy="scroll"
        data-target="#navbar-collapse-toggle"
        data-offset="98"
      >
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/getaquote" component={Contact} />
          <Route path="/contact" component={Home} />
          <Route path="/8460923" component={Site} />
        </Switch>
        <Footer />
        <ScrollToTop />
      </div>
    );
  }
}

export default App;
